import { AppState } from 'react-native'
import 'react-native-url-polyfill/auto'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createClient } from '@supabase/supabase-js'

//const supabaseUrl = 'https://kgkcacroqinnatmasguk.supabase.co'
//const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imtna2NhY3JvcWlubmF0bWFzZ3VrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk2NTM1MDQsImV4cCI6MjAyNTIyOTUwNH0.Lm5k8aDMh0mx3RcpvQKnMYZrG_R1boVkYspvD5firCU'
const supabaseUrl = 'https://efnkuomqbyhbvfmwnulm.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVmbmt1b21xYnloYnZmbXdudWxtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc5ODUzNzMsImV4cCI6MjA0MzU2MTM3M30.BLr0vd4ck1uHS-I9rg22jJ09SGNLdPu_9S7MVXq5mqk'


export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
})

// Tells Supabase Auth to continuously refresh the session automatically
// if the app is in the foreground. When this is added, you will continue
// to receive `onAuthStateChange` events with the `TOKEN_REFRESHED` or
// `SIGNED_OUT` event if the user's session is terminated. This should
// only be registered once.
AppState.addEventListener('change', (state) => {
  if (state === 'active') {
    supabase.auth.startAutoRefresh()
  } else {
    supabase.auth.stopAutoRefresh()
  }
})