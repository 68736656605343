/**
 * Componente: Logo.
 * Descripcion: permite crear una vista de logo
 *
 * @version 1.0.1
 * @author Atomico
 */

import React, {Component} from 'react';
import {View, Text, StyleSheet, Image} from 'react-native';

export default class Logo extends Component {
  render() {
    return (
      <View
        style={[
          localStyle.container,
          this.props.containerStyle,
          {height: this.props.size ? this.props.size + 30 : 60},
        ]}>
        <Image source={require("../../assets/logo.png")} style={localStyle.logo} />
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  container: {
    width: 132,

    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
});
