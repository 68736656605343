import React, { useState, useRef } from 'react';
import { View, Text, Modal, Dimensions, StyleSheet, TouchableWithoutFeedback } from 'react-native';

const Tooltip = ({ 
  isVisible, 
  content, 
  placement = 'top',
  onClose,
  children,
  childrenWrapperStyle = {},
  containerStyle = {},
  tooltipStyle = {},
  triangleStyle = {},
  showOverlay = false,
  overlayColor = 'rgba(0, 0, 0, 0.5)'
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: -200, left: -200 });
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const childRef = useRef(null);
  const tooltipRef = useRef(null);
  const window = Dimensions.get('window');

  const updateTooltipPosition = () => {
    if (!childRef.current || !tooltipRef.current) return;

    // Get the tooltip dimensions first
    tooltipRef.current.measure((x, y, width, height, pageX, pageY) => {
      setTooltipWidth(width);
      
      // Then get the wrapped component position
      childRef.current.measure((cx, cy, childWidth, childHeight, childPageX, childPageY) => {
        // Calculate center position
        const centerX = childPageX + (childWidth / 2) - (width / 2);
        
        // Calculate final position
        let position = {
          top: childPageY - height - 10, // 10 is triangle height
          left: centerX,
        };

        // Screen boundary checks
        if (position.left < 10) position.left = 10;
        if (position.left + width > window.width - 10) {
          position.left = window.width - width - 10;
        }

        // Ensure tooltip doesn't go above screen
        if (position.top < 0) {
          // Switch to bottom placement
          position.top = childPageY + childHeight + 10;
        }

        setTooltipPosition(position);
      });
    });
  };

  // Initial measurement when tooltip becomes visible
  React.useEffect(() => {
    if (isVisible) {
      // Small delay to ensure components are rendered
      setTimeout(updateTooltipPosition, 10);
    }
  }, [isVisible]);

  const renderContent = () => {
    if (React.isValidElement(content)) {
      return <View style={styles.content}>{content}</View>;
    }
    return <Text style={styles.content}>{content}</Text>;
  };

  return (
    <>
      <View ref={childRef} style={[styles.childrenWrapper, childrenWrapperStyle]}>
        {children}
      </View>
      <Modal
        transparent
        visible={isVisible}
        onRequestClose={onClose}
      >
        <TouchableWithoutFeedback onPress={onClose}>
        <View style={[
            styles.container, 
            showOverlay && { backgroundColor: overlayColor },
            containerStyle
          ]}>
            <View
              ref={tooltipRef}
              collapsable={false}
              style={[
                styles.tooltip,
                {
                  top: tooltipPosition.top,
                  left: tooltipPosition.left,
                },
                tooltipStyle,
              ]}
            >
              {renderContent()}
              <View style={[styles.triangle, triangleStyle]} />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  childrenWrapper: {
    alignSelf: 'flex-start',
  },
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  tooltip: {
    position: 'absolute',
    backgroundColor: '#333',
    borderRadius: 8,
    padding: 10,
    maxWidth: 200,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  content: {
    color: '#fff',
    fontSize: 14,
    padding:5,
    marginBottom:5
  },
  triangle: {
    position: 'absolute',
    bottom: -10,
    alignSelf: 'center',
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderTopWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: '#333',
  },
});

export default Tooltip;