import React, { useState, useCallback, useRef, useEffect } from 'react';
import { 
  View, 
  TextInput, 
  ScrollView, 
  StyleSheet,
  Text,
} from 'react-native';

const JsonEditor = ({ 
  initialValue = '{}',
  onValueChange = () => {},
  style = {} 
}) => {
  const getInitialLines = useCallback((text) => {
    const lineCount = (text.match(/\n/g) || []).length + 1;
    return Array.from({ length: lineCount }, (_, i) => String(i + 1));
  }, []);

  const [text, setText] = useState(() => {
    try {
      return JSON.stringify(JSON.parse(initialValue), null, 2);
    } catch (e) {
      return '{}';
    }
  });
  
  const [lines, setLines] = useState(() => getInitialLines(text));
  const [isValid, setIsValid] = useState(true);
  const lineNumbersRef = useRef(null);
  const textInputRef = useRef(null);

  const updateLineNumbers = useCallback((newText) => {
    const lineCount = (newText.match(/\n/g) || []).length + 1;
    setLines(Array.from({ length: lineCount }, (_, i) => String(i + 1)));
  }, []);

  const handleChangeText = useCallback((newText) => {
    setText(newText);
    updateLineNumbers(newText);

    try {
      const parsed = JSON.parse(newText);
      setIsValid(true);
      onValueChange(parsed);
    } catch (e) {
      setIsValid(false);
    }
  }, [onValueChange, updateLineNumbers]);

  const formatJson = useCallback(() => {
    try {
      const parsed = JSON.parse(text);
      const formatted = JSON.stringify(parsed, null, 2);
      setTimeout(() => {
        handleChangeText(formatted);
      }, 0);
    } catch (e) {
      // If JSON is invalid, don't format
    }
  }, [text, handleChangeText]);

  // Handle scroll events from TextInput
  const handleContentSizeChange = useCallback((width, height) => {
    if (textInputRef.current) {
      textInputRef.current.measure((x, y, width, height, pageX, pageY) => {
        const scrollY = pageY;
        if (lineNumbersRef.current && scrollY !== undefined) {
          lineNumbersRef.current.scrollTo({
            y: scrollY,
            animated: false
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    updateLineNumbers(text);
  }, [text, updateLineNumbers]);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.lineNumbersWrapper}>
        <ScrollView 
          ref={lineNumbersRef}
          style={styles.lineNumbers}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
        >
          {lines.map((num) => (
            <Text key={num} style={styles.lineNumber}>
              {num}
            </Text>
          ))}
        </ScrollView>
      </View>

      <View style={styles.editorContainer}>
        <TextInput
          ref={textInputRef}
          style={[
            styles.editor,
            !isValid && styles.invalidJson
          ]}
          multiline
          scrollEnabled={true}
          onContentSizeChange={handleContentSizeChange}
          value={text}
          onChangeText={handleChangeText}
          autoCapitalize="none"
          autoCorrect={false}
          spellCheck={false}
          keyboardType="ascii-capable"
          onBlur={formatJson}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#1E1E1E',
    borderRadius: 8,
    overflow: 'hidden',
    flex: 1,
  },
  lineNumbersWrapper: {
    width: 45,
    backgroundColor: '#252526',
    flexShrink: 0,
  },
  lineNumbers: {
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  lineNumber: {
    color: '#858585',
    fontSize: 12,
    fontFamily: 'monospace',
    lineHeight: 20,
    textAlign: 'right',
  },
  editorContainer: {
    flex: 1,
    paddingVertical: 8,
  },
  editor: {
    flex: 1,
    color: '#D4D4D4',
    fontSize: 12,
    fontFamily: 'monospace',
    padding: 0,
    paddingHorizontal: 8,
    lineHeight: 20,
    textAlignVertical: 'top',
  },
  invalidJson: {
    color: '#F48771',
  },
});

export default JsonEditor;