// components/menus/CustomDrawerContent.js
import React from "react";
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import { View, Text, StyleSheet, Alert, Touchable } from "react-native";
import menuConfigData from "../../config/menuConfig.json"; // Adjust the path as needed
import { useNavigation } from "@react-navigation/native";
import { supabase } from "../../backend/supabase";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";

const CustomDrawerContent = (props) => {
  const navigation = useNavigation();
  const [menuConfig, setMenuConfig] = React.useState(menuConfigData);

  const handleMenuItemPress = (item) => {
    if (item.route) {
      navigation.navigate(item.route);
    } else if (item.customFunction && functionsMap[item.customFunction]) {
      console.log(`Executing custom function for menu item "${item.customFunction}"`);
      functionsMap[item.customFunction]();
    } else {
      console.warn(`No action defined for menu item "${item.name}"`);
    }
  };

  // Define your custom functions
  const functionsMap = {
    openHelpModal: () => {
      // Implement your help modal logic
      console.log("Opening help modal...");
      // Example: navigation.navigate('HelpModal');
    },
    handleLogout: () => {
      console.log("Logging out...");
      // Implement your logout logic
      
            supabase.auth.signOut();
      
      // Example: Clear user session and navigate to login screen
    },
  };

  // Get the current route name to determine the selected item
  const getCurrentRouteName = () => {
    const route = navigation.getState();
    if (route && route.routes && route.routes.length > 0) {
      return route.routes[route.index].name;
    }
    return null;
  };

  const currentRouteName = getCurrentRouteName();
  console.log("Current route name:", currentRouteName);
  return (
    <DrawerContentScrollView {...props}>
      {/* Header */}
      <View style={styles.header}>
        <View style={styles.headerLogo}><Text style={styles.headerTitle}>{"</>"} codes</Text><Text style={styles.headerTitleDot}>.</Text></View>
        <TouchableOpacity
          style={styles.closeButton}
          onPress={() => props.navigation.closeDrawer()}
        >
          <MaterialCommunityIcons name="close" size={24} color="#fff" />
        </TouchableOpacity>
      </View>
      {/* Menu Items */}
      {menuConfig.map((item, index) => {
        const isActive = item.route === currentRouteName;
        if (item.type && item.type === "divider") {
          return <View key={index} style={{ height:item.height || 100 }} />;
        } else {
          return (
            <DrawerItem
              key={index}
              label={item.name}
              icon={({ color, size }) => <MaterialCommunityIcons name={item.icon} color={color} size={size} />}
              focused={isActive}
              onPress={() => handleMenuItemPress(item)}
            />
          );
        }
      })}
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 16,
    backgroundColor: "#333",
    marginBottom: 30,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerLogo: {
    flexDirection: "row",
  },
  headerTitle: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  headerTitleDot: {
    color: "#2E88FF",
    fontSize: 18,
    fontWeight: "bold",
  },
});

export default CustomDrawerContent;
