import NavigationConfig from "./navigationConfig.json";
import LayoutsConfig from "./layoutViews.json";
import DynamicViewTestConfig from "./dynamicViewTest.json";
import ActionsModuleConfig from "./actionModuleConfig.json";
import PublicPagesConfig from "./publicPagesConfig.json";

const viewConfigRegistry = {
    'navigationConfig.json': NavigationConfig,
    'layoutViews.json': LayoutsConfig,
    'dynamicViewTest.json': DynamicViewTestConfig,
    'actionsModuleConfig.json': ActionsModuleConfig,
    'publicPagesConfig.json': PublicPagesConfig,
    // ... add other configurations
  };
  
  export default viewConfigRegistry;