// screens/HomePage.js
import React from "react";
import { View, Text } from "react-native";
import ActionButton from "../components/buttons/ActionButton";
import { useNavigation } from "@react-navigation/native";

const HomePage = () => {
    const navigation = useNavigation();
  
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>Welcome to Simple 𝛂</Text>
        <ActionButton label="Login" main action={() => navigation.navigate("Auth")} />
      </View>
  );
};

export default HomePage;
