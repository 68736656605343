import React, { useState } from "react";
import { Alert, StyleSheet, View, Text } from "react-native";
import { supabase } from "./supabase";
import { Button, Input } from "react-native-elements";
import ScreenView from "../components/views/ScreenView";
import TextField from "../components/inputs/textField";
import Logo from "../components/branding/logo";
import ActionButton from "../components/buttons/ActionButton";
import { colors } from "../styles";

export default function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  async function signInWithEmail() {
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) Alert.alert(error.message);
    setLoading(false);
  }

  async function signUpWithEmail() {
    setLoading(true);
    const {
      data: { session },
      error,
    } = await supabase.auth.signUp({
      email: email,
      password: password,
    });

    if (error) Alert.alert(error.message);
    if (!session) Alert.alert("Please check your inbox for email verification!");
    setLoading(false);
  }

  return (
    <ScreenView>
      <View style={styles.container}>
        <Logo containerStyle={{ marginTop: 50 }} />
        <Text style={{ opacity: 0.3 }}>version 1.0.0 (b6)</Text>
        <View style={[styles.verticallySpaced, styles.mt20, { width: "90%", marginLeft: "5%" }]}>
          <TextField
            label="Email"
            leftIcon={{ type: "font-awesome", name: "envelope" }}
            onChangeText={(text) => setEmail(text)}
            value={email}
            placeholder="email@address.com"
            autoCapitalize={"none"}
          />
        </View>
        <View style={[styles.verticallySpaced, { width: "90%", marginLeft: "5%" }]}>
          <TextField
            label="Password"
            leftIcon={{ type: "font-awesome", name: "lock" }}
            onChangeText={(text) => setPassword(text)}
            value={password}
            password
            secureTextEntry={true}
            placeholder="Password"
            autoCapitalize={"none"}
          />
        </View>
        <View style={[styles.verticallySpaced, styles.mt20, {width:"40%"}]}>
          <ActionButton main label="Sign in" disabled={loading} action={() => signInWithEmail()} />
          {/* <ActionButton label="Sign up" disabled={loading} main action={() => signUpWithEmail()} containerStyle={{backgroundColor:colors.jetBlack}} /> */}
        </View>
        <View style={styles.verticallySpaced}></View>
      </View>
    </ScreenView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "50%",
    height: 600,
    marginTop: 40,
    padding: 12,
    borderRadius: 15,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    borderWidth:0.5,
    borderColor:"#F4F4F4"
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  mt20: {
    marginTop: 20,
  },
});
