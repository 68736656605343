/**
 * Componente: TextField.
 * Descripcion: permite crear una vista de TextField.
 *
 * @version 1.0.1
 * @author Atomico
 */

import React, { Component, createRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Image,
  TouchableOpacity,
} from "react-native";
import { colors, titles } from "../../styles";

export default class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
    this.textInputRef = React.createRef();
  }
  blurTextInput = () => {
    this.textInputRef.current.blur();
  };
  
  render() {
    return (
      <View style={[localStyle.container, this.props.containerStyle]}>
        {this.props.label && (
          <Text style={[localStyle.label, this.props.labelStyle]} allowFontScaling={false}>
            {" "}
            {this.props.label}
          </Text>
        )}

        <TextInput
          style={[localStyle.textInput, this.props.textInputStyle]}
          placeholderTextColor={"#8C8396"}
          maxLength={this.props.maxLength ? this.props.maxLength : null}
          keyboardType={
            this.props.keyboardType ? this.props.keyboardType : "default"
          }
          secureTextEntry={this.props.password && this.state.hidden === true ? true:false}
          autoCapitalize={
            this.props.autoCapitalize ? this.props.autoCapitalize : "sentences"
          }
          autoCorrect={this.props.autoCorrect ? this.props.autoCorrect : false}
          autoCompleteType={
            this.props.autoCompleteType ? this.props.autoCompleteType : "off"
          }
          textContentType={
            this.props.textContentType ? this.props.textContentType : "none"
          }
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          value={this.props.value}
          editable={this.props.editable === false ? false : true}
          onChangeText={(input) => {
            this.props.onChangeText ? this.props.onChangeText(input) : null;
          }}
          onFocus={this.props.onFocus ? this.props.onFocus : null}
          onBlur={this.props.onBlur ? this.props.onBlur : null}
          onEndEditing={this.props.onEndEditing ? this.props.onEndEditing : null}
          returnKeyType={
            this.props.returnKeyType ? this.props.returnKeyType : null
          }
          ref={this.textInputRef} 
        />
        {this.props.password && (
          <TouchableOpacity
            style={[localStyle.eyeButton, { top: this.props.label ? 35 : 20 }]}
            onPress={() => this.setState({ hidden: !this.state.hidden })}
          >
            <Image
              source={
                this.state.hidden
                  ? require("../../assets/icons/eye_closed.png")
                  : require("../../assets/icons/eye_open.png")
              }
              style={[localStyle.eye]}
            />
          </TouchableOpacity>
        )}
        {this.props.tip && (
          <Text style={[localStyle.tip, this.props.tipStyle]}>
            {this.props.tip}
          </Text>
        )}
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  container: {
    minWidth: 50,
    width: "100%",
    marginVertical: 10,
    justifyContent: "center",
  },
  textInput: {
    width: "95%",
    height: 50,
    backgroundColor: "white",
    borderRadius: 15,
    borderWidth: 0.5,
    borderColor: "#DDE1E4",
    paddingLeft: 20,
  },
  label: {
    marginVertical: 5,
    paddingLeft: 15,
    color: colors.jetBlack,
    fontSize: 16,
    fontWeight:700,
    marginBottom:10
  },
  eye: {
    width: 24,
    height: 17,
    marginTop:15,
    marginRight:15,
    resizeMode: "contain",
  },
  eyeButton: {
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
    position: "absolute",
    right: 25,
    top: 10,
  },
  tip: {
    fontSize: 12,
    marginVertical: 10,
    paddingLeft: 10,
    opacity: 0.7,
  },
});
