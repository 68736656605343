// DynamicDataWrapper.js
import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import { supabase } from '../../backend/supabase';
import ViewBuilder from '../views/ViewBuilder';
import { Loading } from '../loadings';

const DynamicDataWrapper = ({
  settings,
  config,
  functionsMap = {},
  extraProps = {},
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { id } = route.params || {};

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data for id', { id, settings, config });
      if (id) {
        const { data: record, error } = await supabase
          .from(settings.data.table)
          .select('*')
          .eq('id', id)
          .single();
        console.log('Fetch results', { record, error });
        if (error) {
          console.error('Error fetching data:', error);
        } else {
          setData(record);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <Loading />;
  } else if (!data) {
    return (
      <View style={{ padding: 16 }}>
        <Text>No data found.</Text>
      </View>
    );
  } else {
    console.log('About to start', config);

    // Combine extraProps with the fetched data
    const combinedExtraProps = { ...extraProps, data };

    // Use ViewBuilder as a component
    return (
      <ViewBuilderComponent
        config={config}
        navigation={navigation}
        functionsMap={functionsMap}
        extraProps={combinedExtraProps}
      />
    );
  }
};

export default DynamicDataWrapper;
