/**
 * Componente: Loading.
 * Descripcion: permite crear una vista de Loading.
 *
 * @version 1.0.1
 * @author Atomico
 */

import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";

import { colors } from "../../styles";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
export class Loading extends Component {
  render() {
    return (
      <View
        style={[
          localStyle.wrapper,
          this.props.backgroundStyle,
          { left: this.props.active ? 0 : windowWidth * 10 },
        ]}
      >
        <View style={[localStyle.container, this.props.containerStyle]}>
          <Image source={require("../../assets/loading_blue.gif")} style={localStyle.image} /> 
          <Text style={{fontSize:12, color:"#666"}}>
            loading...
          </Text>
         
        </View>
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  wrapper: {
    width: windowWidth,
    height: windowHeight*1.2,
    position:"absolute",
    top:windowHeight*-.2,
    zIndex:100,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
  },
  container: {
    width: "100%",
    backgroundColor: "#FFF",
    height: 400,
    alignItems: "center",
    justifyContent: "center",
  },
  textLabel: {
    fontSize: 20,
    color: "white",
  },
  image: {
    width: 200,
    height: 200,
    resizeMode:"contain"
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: "contain",
  },
});
