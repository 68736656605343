import React, { createContext, useContext, useEffect, useState } from "react";
import { View, Text, Image, Button, TouchableOpacity } from "react-native";
import { supabase } from "../../backend/supabase";
import componentRegistry from "../componentRegistry";

// Create context for state management
const StateContext = createContext(null);

// Base component mapping
const baseComponentMap = {
  View,
  Text,
  Image,
  Button,
  TouchableOpacity,
};

// Custom hook for state management
const useStateManager = (stateConfig) => {
  const [state, setState] = useState(() => {
    if (!stateConfig) return {};
    return Object.fromEntries(Object.entries(stateConfig).map(([key, value]) => [key, value.initial]));
  });

  const [loadingStates, setLoadingStates] = useState({});
  const [errors, setErrors] = useState({});

  const manager = {
    getState: (name) => state[name],
    setState: (name, value) => {console.log("setState",{name,value}); setState((prev) => ({ ...prev, [name]: value }))},
    getAllState: () => state, // Add this line
    getLoadingState: (name) => loadingStates[name],
    setLoadingState: (name, value) => setLoadingStates((prev) => ({ ...prev, [name]: value })),
    getError: (name) => errors[name],
    setError: (name, value) => setErrors((prev) => ({ ...prev, [name]: value })),
  };

  return manager;
};

const useDataFetch = (dataFetch, stateManager, context) => {
  useEffect(() => {
    if (!dataFetch || !stateManager) {
      console.warn("Missing dataFetch or stateManager:", { dataFetch, stateManager });
      return;
    }

    const fetchData = async () => {
      const { type, params, stateName } = dataFetch;

      // Resolve variables in params
      const resolvedParams = resolveVariables(params, context);

      try {
        stateManager.setLoadingState(stateName, true);
        stateManager.setError(stateName, null);

        let result;
        if (type === "local") {
          let query = supabase.from(resolvedParams.table).select(resolvedParams.query_select || "*");

          if (resolvedParams.query_conditionals) {
            resolvedParams.query_conditionals.forEach((condition) => {
              const operator = condition.operator;
              const field = condition.field;
              const value = condition.value;
              query = query[operator](field, value);
            });
          }

          const { data, error } = await query;
          if (error) throw error;
          result = data;

          // If expecting a single record, set result to the first item
          if (Array.isArray(result) && result.length === 1) {
            result = result[0];
          }
        } else if (type === "remote") {
          const response = await fetch(resolvedParams.url, {
            method: resolvedParams.method || "GET",
            headers: resolvedParams.headers || { "Content-Type": "application/json" },
            ...(resolvedParams.body && { body: JSON.stringify(resolvedParams.body) }),
          });

          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          result = await response.json();
        }

        stateManager.setState(stateName, result);
      } catch (error) {
        console.error("Data fetch error:", error);
        stateManager.setError(stateName, error.message);
      } finally {
        stateManager.setLoadingState(stateName, false);
      }
    };

    fetchData();
  }, [dataFetch, context.variables.route]); // Include context in dependencies
};



// Variable resolution function with special handling for children
const resolveVariables = (expression, context) => {
  if (!expression) return expression;
  if (React.isValidElement(expression)) return expression;

  // Do not process function configurations
  if (typeof expression === "object" && expression.type === "function") {
    return expression;
  }

  // Special handling for {{children}} syntax
  if (expression === "{{children}}") {
    return context.variables.children;
  }

  // Handle state references
  if (typeof expression === "object" && expression.type === "state") {
    const { scope = "local", name } = expression;
    return scope === "root" ? context.rootState?.getState(name) : context.stateManager?.getState(name);
  }

  // Handle string interpolation
  if (typeof expression === "string") {
    // Handle {{{variable}}} syntax
    if (expression.includes("{{{") && expression.includes("}}}")) {
      return expression.replace(/\{\{\{(.*?)\}\}\}/g, (_, path) => {
        const keys = path.trim().split(".");
        let value = context.variables;
        for (const key of keys) {
          value = value?.[key];
        }
        return value ?? "";
      });
    }

    // Handle ${variable} syntax
    if (expression.includes("${") && expression.includes("}")) {
      return expression.replace(/\$\{(.*?)\}/g, (_, path) => {
        const keys = path.trim().split(".");
        let value = context.variables;
        for (const key of keys) {
          value = value?.[key];
        }
        return value ?? "";
      });
    }

    return expression;
  }

  // Handle arrays
  if (Array.isArray(expression)) {
    return expression.map((item) => resolveVariables(item, context));
  }

  // Handle objects
  if (typeof expression === "object") {
    const resolved = {};
    for (const [key, value] of Object.entries(expression)) {
      resolved[key] = resolveVariables(value, context);
    }
    return resolved;
  }

  return expression;
};


// Function to handle action/function resolution
const resolveFunctionCall = (functionConfig, navigation, functionsMap) => {
  if (!functionConfig || typeof functionConfig !== "object") return functionConfig;
  console.log("Resolving function call:", functionConfig);
  if (functionConfig.type === "function") {
    const { name, params = [] } = functionConfig;

    if (name === "navigate") {
      return () => navigation.navigate(...params);
    }

    if (name === "goBack") {
      return () => navigation.goBack();
    }

    if (functionsMap[name]) {
      return () => functionsMap[name](...params);
    }

    if (name.startsWith("navigation.")) {
      const navigationMethod = name.split(".")[1];
      return () => navigation[navigationMethod](...params);
    }

    console.warn(`Function "${name}" not found in functionsMap`);
    return () => {};
  }

  return functionConfig;
};

// Props resolution function
const resolveProps = (props, context) => {
  if (!props) return props;

  if (typeof props !== 'object' || Array.isArray(props)) {
    // For non-object or array props, resolve variables directly
    return resolveVariables(props, context);
  }

  const resolvedProps = {};
  for (const [key, value] of Object.entries(props)) {
    if (typeof value === "object" && value.type === "function") {
      // Handle function configurations
      resolvedProps[key] = resolveFunctionCall(value, context.navigation, context.functionsMap);
    } else {
      // Resolve variables in other props
      resolvedProps[key] = resolveVariables(value, context);
    }
  }
  return resolvedProps;
};


// Component renderer
const ComponentRenderer = ({ config, navigation, route, functionsMap, extraProps = {}, parentStateManager }) => {
  // Initialize state management
  const rootState = useContext(StateContext);
  const stateManager = config.state ? useStateManager(config.state) : parentStateManager;

  if (!config) return null;


  const { type, props, children } = config;
  const Component = baseComponentMap[type] || componentRegistry[type];

  if (!Component) {
    console.error(`Component "${type}" not found in registry:`, type);
    return null;
  }

  // Create resolution context
  const context = {
    variables: {
      ...extraProps,
      ...(stateManager?.getAllState() || {}),
      ...(rootState?.getAllState() || {}),
      route: route || {},
    },
    stateManager,
    rootState,
    navigation,
    functionsMap,
  };

  // Handle data fetching using the correct stateManager and context
  if (config.dataFetch) {
    useDataFetch(
      config.dataFetch,
      config.state?.scope === "root" ? rootState : stateManager,
      context
    );
  }

  // Rest of the component remains the same...
  const resolvedProps = resolveProps(props, context);

  const renderChildren = () => {
    if (Array.isArray(children)) {
      return children.map((child, index) => (
        <ComponentRenderer
          key={index}
          config={child}
          navigation={navigation}
          functionsMap={functionsMap}
          extraProps={extraProps}
          parentStateManager={stateManager}
        />
      ));
    }

    if (typeof children === "object" && children !== null) {
      return (
        <ComponentRenderer
          config={children}
          navigation={navigation}
          functionsMap={functionsMap}
          extraProps={extraProps}
          parentStateManager={stateManager}
        />
      );
    }

    return resolveVariables(children, context);
  };

  return (
    <Component {...resolvedProps} navigation={navigation}>
      {renderChildren()}
    </Component>
  );
};

// Main ViewBuilder function
const ViewBuilder = (config, navigation, route, functionsMap = {}, extraProps = {}) => {
  if (!config) return null;

  // Handle root state components
  if (config.state?.scope === "root") {
    return (
      <StateContext.Provider value={useStateManager(config.state)}>
        <ComponentRenderer
          config={config}
          navigation={navigation}
          route={route}
          functionsMap={functionsMap}
          extraProps={extraProps}
        />
      </StateContext.Provider>
    );
  }

  // Handle regular components
  return (
    <ComponentRenderer config={config} route={route} navigation={navigation} functionsMap={functionsMap} extraProps={extraProps} />
  );
};

export default ViewBuilder;
