/**
 * Componente: ScreenView.
 * Descripcion: permite crear una vista Default 
 * que incluye loading y puede llamarse como scroll o como vista fija.
 *
 * @version 1.0.1
 * @author Atomico Software
 */

import React, {Component} from 'react';
import {SafeAreaView, StyleSheet, View} from 'react-native';
import {Loading} from '../loadings';
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import { colors } from '../../styles';

export default class ScreenView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // if (this.props.scroll || this.props.keyboard) {
    //   return (
    //     <View style={[localStyle.container, this.props.style]}>
    //       {this.props.keyboard ? (
    //         <KeyboardAwareScrollView
    //           keyboardShouldPersistTaps="handled"
    //           contentContainerStyle={[localStyle.scrollContentStyle, this.props.scrollContentStyle]}
    //           horizontal={false}
    //           style={[localStyle.scrollStyle,this.props.scrollStyle]}>
    //           {this.props.children}
    //         </KeyboardAwareScrollView>
    //       ) : (
    //         <KeyboardAwareScrollView
    //           scrollEnabled={this.props.loading === true ? false : true}
    //           contentContainerStyle={[localStyle.scrollContentStyle,this.props.scrollContentStyle]}
    //           scrollTo={
    //             this.props.loading === true
    //               ? {
    //                   y: 0,
    //                   animated: true,
    //                 }
    //               : null
    //           }
    //           style={localStyle.scrollStyle}>
    //           {this.props.children}
    //           </KeyboardAwareScrollView>
    //       )}
    //       <Loading active={this.props.loading} />
    //     </View>
    //   );
    // }

    return (
      <SafeAreaView style={[localStyle.container, this.props.style]}>
        {this.props.children}
        <Loading active={this.props.loading} />
      </SafeAreaView>
    );
  }
}

const localStyle = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    minHeight: '100%',
    backgroundColor: colors.lightBeige,
    alignItems: 'center',
  },
  scrollStyle: {
    flex: 1,
    width: '100%',
  },
  scrollContentStyle: {alignItems: 'center', paddingBottom: 40},
});


