// DataTable.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { View, Text, TouchableOpacity, StyleSheet, FlatList, TextInput, Modal, Dimensions } from "react-native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import _ from "lodash";
import { supabase } from "../../backend/supabase";
import { Loading } from "../loadings";
import { useNavigation } from "@react-navigation/native";
import Tooltip from "../tooltips/Tooltip";
import { useAlertDialog } from "../alerts/AlertDialog";

const DataTable = React.memo(({ settings }) => {
  console.log("DataTable component", settings);

  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filters, setFilters] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [currentFilterColumn, setCurrentFilterColumn] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [filterCondition, setFilterCondition] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState(null);

  const { show: showAlert, AlertDialogComponent } = useAlertDialog();
  const pageSize = settings.properties.default_page_size;

  const windowWidth = Dimensions.get("window").width;
  const isSmallScreen = windowWidth < 360;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setLoading(true);
    await queryData();
    // Removed queryBackend from here
    setLoading(false);
  };

  const queryData = async () => {
    //console.log("Querying backend", fields);
    let query = supabase.from(settings.data.table).select(settings.data.default_select_query);

    if (settings.filters && settings.filters.length > 0 && selectedFilter) {
      for (const filter of settings.filters[0].query_filters) {
        console.log("we have filter", filter);
        if (
          [
            "eq",
            "neq",
            "gt",
            "gte",
            "lt",
            "lte",
            "contains",
            "containedBy",
            "rangeGt",
            "rangeGte",
            "rangeLt",
            "rangeLte",
            "overlaps",
          ].includes(filter.type)
        ) {
          query = query[filter.type](filter.field_name, filter.filter_value);
        } else if (["like", "ilike"].includes(filter.type)) {
          query = query[filter.type](filter.field_name, "%" + filter.filter_value + "%");
        } else if (["not", "filter"].includes(filter.type)) {
          query = query[filter.type](filter.field_name, filter.operator, filter.filter_value);
        }
      }
    }

    const { data: queryData, error } = await query;

    console.log("query backend returned", { data: queryData, error });
    if (error) {
      console.log("data fetch error: ", error);
      alert("We had an error getting " + currentTable + " data");
    } else {
      if (queryData && queryData.length > 0) {
        setData(queryData);
      } else {
        setWelcome(true);
        setData([]);
      }
    }
    return true;
  };

  const delete_item = async (id) => {
    setLoading(true);
    try {
      const { error } = await supabase.from(settings.data.table).delete().eq("id", id);

      if (error) {
        throw error;
      }

      // Remove the deleted item from the local state
      setData((prevData) => prevData.filter((item) => item.id !== id));

      // Show success message
      Alert.alert("Success", "Item deleted successfully", [{ text: "OK" }]);
    } catch (error) {
      console.error("Error deleting item:", error);
      Alert.alert("Error", "Failed to delete item: " + (error.message || "Unknown error"), [{ text: "OK" }]);
    } finally {
      setLoading(false);
    }
  };

  const functions = {
    delete_item,
    logPress : (item) => {
        console.log("Item pressed", item);
    }
  };

  const evaluateParam = (param, item) => {
    if (typeof param === "string" && param.includes("{{") && param.includes("}}")) {
      const field = param.match(/{{(.*?)}}/)[1].split("item.")[1];
      console.log("Evaluating parameter", { param, field, result: item[field] });
      return item[field];
    }
    return param;
  };

  const processActionParams = (params, item) => {
    console.log("Processing action params", { params, item });
    if (typeof params === "object") {
      return Object.keys(params).reduce((acc, key) => {
        acc[key] = evaluateParam(params[key], item);
        console.log("Processed param", { key, value: acc[key] });
        return acc;
      }, {});
    }
    return params;
  };

  const handleAction = useCallback((action, item) => {
    if (action.confirm) {
      showAlert(
        action.confirm.title,
        action.confirm.message,
        [
          {
            text: action.confirm.cancelText || 'Cancel',
            style: 'cancel',
            onPress: () => {} // No-op
          },
          {
            text: action.confirm.okText || 'OK',
            style: 'destructive',
            onPress: () => {
              if (action.type === 'function') {
                const functionParams = processActionParams(action.params.args, item);
                if (typeof functions[action.params.function_name] === 'function') {
                  functions[action.params.function_name](...functionParams);
                }
              }
            }
          }
        ]
      );
    } else {
      // Handle non-confirmation actions as before
      switch (action.type) {
        case "navigate":
          const navParams = processActionParams(action.params.args, item);
          console.log("Navigating to", action.params.route, navParams);
          navigation.navigate(action.params.route, navParams);
          break;
        case "function":
          const functionParams = processActionParams(action.params.args, item);
          if (typeof functions[action.params.function_name] === "function") {
            functions[action.params.function_name](...functionParams);
          }
          break;
      }
    }
  }, [navigation, functions, showAlert]);

  useEffect(() => {
    let filteredData = [...data];

    // Implement search functionality
    if (searchText) {
      filteredData = filteredData.filter((item) =>
        settings.columns.some((col) => {
          const value = item[col.name];
          return value && value.toString().toLowerCase().includes(searchText.toLowerCase());
        })
      );
    }

    // Implement column filters
    Object.keys(filters).forEach((key) => {
      const { condition, value } = filters[key];
      const column = settings.columns.find((col) => col.name === key);

      if (column) {
        filteredData = filteredData.filter((item) => {
          const itemValue = item[key];

          switch (condition) {
            case "is_empty":
              return !itemValue;
            case "is_not_empty":
              return !!itemValue;
            case "contains":
              return itemValue?.toString().toLowerCase().includes(value.toLowerCase());
            case "does_not_contain":
              return !itemValue?.toString().toLowerCase().includes(value.toLowerCase());
            case "greater_than":
              return itemValue > parseFloat(value);
            case "less_than":
              return itemValue < parseFloat(value);
            case "equal_to":
              return itemValue == value;
            default:
              return true;
          }
        });
      }
    });

    // Implement sorting
    if (sortConfig.key) {
      filteredData = _.orderBy(filteredData, [sortConfig.key], [sortConfig.direction]);
    }

    // Implement pagination
    const startIndex = (currentPage - 1) * pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

    setTableData(paginatedData);
  }, [data, sortConfig, filters, searchText, currentPage, pageSize, settings.columns]);

  // Handle sorting
  const handleSort = useCallback(
    (columnName) => {
      let direction = "asc";
      if (sortConfig.key === columnName && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key: columnName, direction });
    },
    [sortConfig]
  );

  // Open filter modal
  const openFilterModal = useCallback((column) => {
    setCurrentFilterColumn(column);
    setFilterModalVisible(true);
    setFilterValue("");
    setFilterCondition("");
  }, []);

  // Apply filter
  const applyFilter = useCallback(() => {
    if (filterCondition === "is_empty" || filterCondition === "is_not_empty") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [currentFilterColumn.name]: { condition: filterCondition, value: null },
      }));
    } else if (filterValue !== "") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [currentFilterColumn.name]: { condition: filterCondition, value: filterValue },
      }));
    }
    setFilterModalVisible(false);
    setCurrentPage(1);
  }, [currentFilterColumn, filterCondition, filterValue]);

  // Clear filter
  const clearFilter = useCallback(() => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[currentFilterColumn.name];
      return updatedFilters;
    });
    setFilterModalVisible(false);
    setCurrentPage(1);
  }, [currentFilterColumn]);

  // Render filter modal
  const renderFilterModal = useCallback(() => {
    if (!currentFilterColumn) return null;

    const { type } = currentFilterColumn;

    const conditions =
      type === "string"
        ? [
            { label: "Is Empty", value: "is_empty" },
            { label: "Is Not Empty", value: "is_not_empty" },
            { label: "Contains", value: "contains" },
            { label: "Does Not Contain", value: "does_not_contain" },
          ]
        : [
            { label: "Is Empty", value: "is_empty" },
            { label: "Is Not Empty", value: "is_not_empty" },
            { label: "Greater Than", value: "greater_than" },
            { label: "Less Than", value: "less_than" },
            { label: "Equal To", value: "equal_to" },
          ];

    return (
      <Modal
        transparent={true}
        visible={filterModalVisible}
        animationType="fade"
        onRequestClose={() => setFilterModalVisible(false)}
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>Filter by {currentFilterColumn.label}</Text>
            <View style={styles.conditionsContainer}>
              {conditions.map((item) => (
                <TouchableOpacity
                  key={item.value}
                  style={[styles.conditionItem, filterCondition === item.value && styles.selectedCondition]}
                  onPress={() => {
                    setFilterCondition(item.value);
                  }}
                >
                  <Text style={styles.conditionText}>{item.label}</Text>
                </TouchableOpacity>
              ))}
            </View>
            {["contains", "does_not_contain", "greater_than", "less_than", "equal_to"].includes(filterCondition) && (
              <TextInput
                style={styles.filterInput}
                placeholder="Enter value"
                value={filterValue}
                onChangeText={(text) => setFilterValue(text)}
              />
            )}
            <View style={styles.modalButtons}>
              <TouchableOpacity style={styles.applyButton} onPress={applyFilter}>
                <Text style={styles.buttonText}>Apply</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.clearButton} onPress={clearFilter}>
                <Text style={styles.buttonText}>Clear</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  }, [applyFilter, clearFilter, currentFilterColumn, filterModalVisible, filterValue, filterCondition]);

  // Render table header
  const renderHeader = useCallback(() => {
    return (
      <View style={styles.headerRow}>
        {settings.properties.show_row_numbers && (
          <View style={styles.leftHeaderContainer}>
            <Text style={[styles.row_headerCell, styles.rowNumberHeader]}>#</Text>
          </View>
        )}
        {settings.columns.map((col) => {
          const isSorted = sortConfig.key === col.name;
          const isFiltered = filters[col.name];
          return (
            <View key={col.name} style={styles.headerCellContainer}>
              <TouchableOpacity style={styles.headerCell} onPress={() => handleSort(col.name)}>
                <Text style={styles.headerText}>{col.label}</Text>
                {col.sort_by && (
                  <Icon
                    name={isSorted ? (sortConfig.direction === "asc" ? "arrow-up" : "arrow-down") : "arrow-up-down"}
                    size={16}
                    color={isSorted ? "#000" : "#C4C4C4"}
                  />
                )}
              </TouchableOpacity>
              {col.filter_by && (
                <TouchableOpacity onPress={() => openFilterModal(col)} style={styles.filterIcon}>
                  <Icon name="filter-variant" size={16} color={isFiltered ? "#007bff" : "#000"} />
                </TouchableOpacity>
              )}
            </View>
          );
        })}
        {settings.actions?.column_settings?.show && (
          <View style={[styles.actionsHeaderContainer, { width: settings.actions.column_settings.width || 100 }]}>
            <Icon name="dots-horizontal" size={16} />
          </View>
        )}
      </View>
    );
  }, [filters, handleSort, openFilterModal, settings, sortConfig]);

  // Add these components at the top of your DataTable file
  const ActionMenu = ({ actions, onSelect, onClose }) => {
    return (
      <View style={styles.menuContainer}>
        {actions.map((action, index) => (
          <TouchableOpacity
            key={index}
            style={styles.menuItem}
            onPress={() => {
              onSelect(action);
              onClose();
            }}
          >
            <Icon name={action.icon} size={20} color={"#FFF"} />
            <Text style={styles.menuItemText}>{action.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  
  // Render table row
  const renderRow = useCallback(
    ({ item, index }) => {
      const backgroundColor = settings.properties.alternate_bkg_colors && index % 2 === 0 ? "#f9f9f9" : "#fff";
      const actions = settings.actions?.items || [];

      const renderActions = () => {
        if (actions.length <= 2) {
          return actions.map((action, actionIndex) => (
            <Tooltip
              key={actionIndex}
              content={action.label}
              isVisible={tooltipAnchor === `${item.id}-${action.name}`}
              onClose={() => setTooltipAnchor(null)}
            >
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() => {
                  if (action.confirm) {
                    setSelectedItem(item);
                    setSelectedAction(action);
                    setConfirmTooltipVisible(true);
                  } else {
                    handleAction(action, item);
                  }
                }}
              >
                <Icon name={action.icon} size={20} color={action.color || "#000"} />
              </TouchableOpacity>
            </Tooltip>
          ));
        } else {
          return (
            <Tooltip
              isVisible={actionMenuVisible && selectedItem?.id === item.id}
              onClose={() => {
                setActionMenuVisible(false);
                setSelectedItem(null);
              }}
              content={
                <ActionMenu
                  actions={actions}
                  onSelect={(action) => { console.log("handlingAction",{action,item}); handleAction(action, item)}}
                  onClose={() => setActionMenuVisible(false)}
                />
              }
              tooltipStyle={styles.menuTooltip}
            >
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() => {
                  setSelectedItem(item);
                  setActionMenuVisible(true);
                }}
              >
                <Icon name="dots-horizontal" size={20} color="#000" />
              </TouchableOpacity>
            </Tooltip>
          );
        }
      };
      return (
        <View style={[styles.row, { backgroundColor }]}>
          {settings.properties.show_row_numbers && (
            <View style={styles.leftHeaderContainer}>
              <Text style={[styles.row_header_cell, styles.rowNumberCell]}>{index + 1}</Text>
            </View>
          )}
          {settings.columns.map((col) => (
            <Text key={col.name} style={styles.cell}>
              {col.format === "currency" ? `$${item[col.name].toFixed(2)}` : item[col.name]}
            </Text>
          ))}
          {settings.actions?.column_settings?.show && (
            <View style={[styles.actionsCell, { width: settings.actions.column_settings.width || 100 }]}>
              {renderActions()}
            </View>
          )}
        </View>
      );
    },
    [settings, handleAction, actionMenuVisible, selectedItem, tooltipAnchor]
  );

  const totalPages = useMemo(() => Math.ceil(testData.length / pageSize), [testData.length, pageSize]);

  const renderPagination = useMemo(() => {
    return (
      <View style={styles.pagination}>
        <TouchableOpacity disabled={currentPage === 1} onPress={() => setCurrentPage((prev) => prev - 1)}>
          <Text style={[styles.pageButton, currentPage === 1 && styles.disabledButton]}>Previous</Text>
        </TouchableOpacity>
        <Text style={styles.pageInfo}>
          Page {currentPage} of {totalPages}
        </Text>
        <TouchableOpacity disabled={currentPage === totalPages} onPress={() => setCurrentPage((prev) => prev + 1)}>
          <Text style={[styles.pageButton, currentPage === totalPages && styles.disabledButton]}>Next</Text>
        </TouchableOpacity>
      </View>
    );
  }, [currentPage, totalPages]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <View style={styles.container}>
        {/* Search Bar */}
        <TextInput
          style={styles.searchInput}
          placeholder="Search..."
          value={searchText}
          onChangeText={(text) => {
            setSearchText(text);
            setCurrentPage(1);
          }}
        />

        {/* Table Headers */}
        {settings.properties.show_headers && renderHeader()}

        {/* Table Data */}
        <FlatList
          data={tableData}
          renderItem={renderRow}
          keyExtractor={({ item }) => Math.floor(Math.random() * 990000 + 10000)}
        />

        {/* Pagination Controls */}
        {settings.properties.pagination && renderPagination}

        {/* Filter Modal */}
        {filterModalVisible && renderFilterModal()}

        <AlertDialogComponent />
      </View>
    );
  }
});

export default DataTable;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "90%",
    margin: "5%",
    borderRadius: 8,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#ddd",
  },
  searchInput: {
    padding: 8,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  headerRow: {
    flexDirection: "row",
    backgroundColor: "#f1f1f1",
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  headerCellContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  headerCell: {
    flex: 1,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  row_headerCell: {
    flex: 1,
    width: 15,
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    marginRight: 10,
  },
  filterIcon: {
    paddingRight: 20,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#f1f1f1",
  },
  cell: {
    flex: 1,
    padding: 10,
    borderRightWidth: 1,
    borderColor: "#f1f1f1",
  },
  leftHeaderContainer: {
    paddingTop: 5,
    width: 35,
    backgroundColor: "#F1F1F1",
    alignItems: "center",
    justifyContent: "center",
  },
  actionsHeaderContainer: {
    paddingTop: 5,
    width: 35,
    backgroundColor: "#F1F1F1",
    alignItems: "center",
    justifyContent: "center",
  },
  row_header_cell: {
    flex: 1,
    width: 15,
    padding: 5,
    color: "#CCC",
  },
  pagination: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
  },
  pageButton: {
    color: "#007bff",
  },
  disabledButton: {
    color: "#ccc",
  },
  pageInfo: {
    textAlign: "center",
    flex: 1,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    width: "80%",
    maxHeight: "80%",
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 20,
  },
  modalTitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  conditionsContainer: {
    marginBottom: 10,
  },
  conditionItem: {
    paddingVertical: 10,
  },
  conditionText: {
    fontSize: 16,
  },
  selectedCondition: {
    backgroundColor: "#e6f7ff",
  },
  filterInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 4,
    padding: 8,
    marginVertical: 10,
  },
  modalButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  applyButton: {
    backgroundColor: "#007bff",
    borderRadius: 4,
    padding: 10,
    flex: 1,
    marginRight: 5,
  },
  clearButton: {
    backgroundColor: "#6c757d",
    borderRadius: 4,
    padding: 10,
    flex: 1,
    marginLeft: 5,
  },
  buttonText: {
    color: "#fff",
    textAlign: "center",
  },
  rowNumberHeader: {
    width: 20,
    textAlign: "center",
  },
  rowNumberCell: {
    width: 20,
    textAlign: "center",
  },
  actionsCell: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderRightWidth: 1,
    borderColor: "#f1f1f1",
  },
  actionButton: {
    padding: 5,
    marginHorizontal: 2,
  },

  menuTooltip: {
    padding: 0,
    minWidth: 180,
  },
  confirmTooltip: {
    backgroundColor: "#fff",
    minWidth: 250,
    padding: 16,
  },
  menuContainer: {
    paddingVertical: 4,
    backgroundColor:"#333",
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#555",
  },
  menuItemText: {
    marginLeft: 12,
    fontSize: 14,
    color: "#fff",
  },
  confirmContainer: {
    alignItems: "center",
  },
  confirmTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 8,
  },
  confirmMessage: {
    fontSize: 14,
    color: "#666",
    textAlign: "center",
    marginBottom: 16,
  },
  confirmButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 8,
  },
  confirmButton: {
    flex: 1,
    padding: 8,
    borderRadius: 4,
    alignItems: "center",
    marginHorizontal: 4,
  },
  cancelButton: {
    backgroundColor: "#f1f1f1",
  },
  confirmActionButton: {
    backgroundColor: "#dc3545",
  },
  buttonText: {
    fontSize: 14,
    color: "#333",
  },
  confirmText: {
    color: "#fff",
  },
});

const testData = [
  { action_name: "Product A", action_group: "internal", action_description: "Test" },
  { action_name: "Product B", action_group: "internal", action_description: "Test" },
  { action_name: "Product C", action_group: "internal", action_description: "Test" },
  { action_name: "Product D", action_group: "internal", action_description: "Test" },
  // Add more data objects as needed
];
