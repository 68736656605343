export const colors = {
  primaryGreen: "#30F2F9",
  primary: "#559EFF",
  primaryBlue: "#2E88FF",
  storngGray: "#6E758C",
  accent:"#FCC42C",
  lightBeige: "#FAF9F9",
  darkBeige:"#98928B",
  jetBlack:"#3A3335",
  black: "#011627"
};



export const titles = {
  mainTitle: {
    fontSize: 45,
    fontWeight: "bold",
    color: "#1E335C",
    marginBottom: 10,
    maxWidth: "90%",
  },
  subtitle: {
    fontSize: 20,
    color: "#6E758C",
    maxWidth: "90%",
    marginBottom: 10,
  },
  fieldTitle: {
    fontSize: 17,
    color: "#6E758C",
    fontWeight: "600",
    maxWidth: "90%",
  },
  linkTitle: { color: colors.primaryBlue, fontWeight: "bold" },
  h1: {
    fontSize: 45,
    fontWeight: "bold",
    color: "#1E335C",
    marginBottom: 10,
    maxWidth: "90%",
  },
  h2: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#1E335C",
    marginBottom: 10,
    maxWidth: "90%",
  },
  h3: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#1E335C",
    marginBottom: 10,
    maxWidth: "90%",
  },
  h4: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#1E335C",
    marginBottom: 10,
    maxWidth: "90%",
  },
  
};

export const icons = {
  home: require("../assets/icons/home.png"),
  heart: require("../assets/icons/heart.png"),
  cart: require("../assets/icons/cart.png"),
  box: require("../assets/icons/box.png"),
  help: require("../assets/icons/help.png"),
  profile: require("../assets/icons/profile.png"),
  settings: require("../assets/icons/settings.png"),
  tag: require("../assets/icons/tag.png"),
  logout: require("../assets/icons/logout.png"),
  exit: require("../assets/icons/exit.png"),
  arrow_down: require("../assets/icons/arrow_down.png"),
  email: require("../assets/icons/email.png"),
  phone: require("../assets/icons/phone.png"),
  birthday: require("../assets/icons/birthday.png"),
  building: require("../assets/icons/building.png"),
  list: require("../assets/icons/list.png"),
  menu: require("../assets/icons/menu.png"),
  person: require("../assets/icons/person.png"),
  link: require("../assets/icons/link.png"),
  edit: require("../assets/icons/edit.png"),
  more: require("../assets/icons/more.png"),
  note: require("../assets/icons/note.png"),
  camera: require("../assets/icons/camera.png"),
  images: require("../assets/icons/images.png"),
  location: require("../assets/icons/location.png"),
}

export const texts = {
    buttonLabelWhite: { fontSize: 18, fontWeight: "600", color: "white" },
    centered:{textAlign: "center"},
    fullPadded:{width:"90%", marginHorizontal:"5%", marginTop:10},
    f14:{fontSize:14},
    f15:{fontSize:15},
    f16:{fontSize:16},
    f17:{fontSize:17},
    f18:{fontSize:18},
    f19:{fontSize:19},
    f20:{fontSize:20},
    f21:{fontSize:21},
    link:{color:colors.primary, textDecorationLine:"underline"}
}

export const decorations = {
  shadow: {
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowColor: "#222222",
  },
  opacity20: {opacity: 0.2},
  opacity30: {opacity: 0.3},
  opacity40: {opacity: 0.4}

};

export const shapes = {
  card: {
    backgroundColor: "#FFF",
    width: "94%",
    marginLeft: "3%",
    minHeight: 50,
    borderRadius: 12,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.2,
    shadowRadius: 20,
    shadowColor: "#C4C4C4",
    marginVertical:20
  },
  pill:{
      width:"23%",
      height:22,
      borderRadius:8,
      marginRight:5
  }
};
