import React from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const Menu = ({ menuConfig }) => {
  const navigation = useNavigation();

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={{ padding: 10 }}
      onPress={() => {
        if (item.onPress) {
          if (typeof item.onPress === 'function') {
            item.onPress(navigation);
          } else {
            // If item.onPress is a string, it has been resolved to a function by ViewBuilder
            item.onPress(navigation);
          }
        } else if (item.navigateTo) {
          navigation.navigate(item.navigateTo);
        }
      }}
    >
      <Text style={{ fontSize: 16 }}>{item.label}</Text>
    </TouchableOpacity>
  );
  console.log(menuConfig);
  return (
    <FlatList
      data={menuConfig}
      keyExtractor={(item, index) => index.toString()}
      renderItem={renderItem}
      style={{ width: 200, height: '100%', backgroundColor: '#f0f0f0', position: 'fixed', left: 0, top: 60, zindex:100 }}
    />
  );
};

export default Menu;
