import {types, flow} from 'mobx-state-tree';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PrototypeService from '../backend/prototype.service';

export const User = types
  .model('User', {
    version: 'alpha0.1',
    authToken: types.maybe(types.string),
    deviceId: types.maybe(types.string),
    accessToken: types.maybe(types.string),
    refreshToken: types.maybe(types.string),
    id: types.maybe(types.number),
    displayName: types.maybe(types.string),
  })
  .actions(self => ({
    initDeviceId: flow(function* () {
      var deviceId = yield AsyncStorage.getItem('uellbeeDeviceId');
      if (!deviceId) {
        deviceId = yield SecureStore.getItemAsync('uellbeeDeviceId');
        if (!deviceId) {
          deviceId = "1";
        }
        yield AsyncStorage.setItem('uellbeeDeviceId', deviceId);
      }
      self.deviceId = deviceId;
      global.deviceId = deviceId;
      return true;
    }),
    wipeSecureData: flow(function* () {
      //yield SecureStore.deleteItemAsync("var");

      return true;
    }),
    logout: flow(function* (unlink = false) {
      console.log('UserStore:logout:start', {unlink});
      try {
        self.session = false;
        if (unlink === true) {
          self._id = undefined;
          self.firstName = undefined;
          self.lastName = undefined;
          self.email = undefined;
          self.phone = undefined;
        }

        return true;
      } catch (error) {
        console.log('UserStore:logout:error', error);
      }
    }),

    login: flow(function* (email, password) {
      try {

      } catch (error) {
        console.log('UserStore:login:error', error);
        return error;
      }
    }),

    
  }));
