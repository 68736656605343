/**
 * Componente: ActionButton.
 * Descripcion: permite crear una vista de botones de accion.
 *
 * @version 1.0.1
 * @author Atomico
 */

import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity,Dimensions } from "react-native";
import { colors, texts } from "../../styles";


export default class ActionButton extends Component {
  
  handlePress = () => {
    // Google Analytics
    this.props.action
      ? this.props.action()
      : console.log("ActionButton pressed no action");
  };

  render() {
    return (
      <TouchableOpacity
        style={[
          localStyle.container,
          this.props.main ? localStyle.main : null,
          this.props.containerStyle,
        ]}
        onPress={this.handlePress}
        disabled={this.props.disabled}
      >
        <Text
          style={[
            localStyle.label,
            this.props.main ? localStyle.mainLabel : localStyle.mainDisableLabel,
            this.props.style,
          ]}
          allowFontScaling={false}
        >
          {this.props.label ? this.props.label : "label"}
        </Text>
      </TouchableOpacity>
    );
  }
}

const localStyle = StyleSheet.create({
  container: {
    minWidth: 50,
    width: "100%",
    height: 56,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primary,
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 20,
    opacity:0.3,
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowColor: "#000",
  },
  label: {
    fontWeight: "bold",
  },
  main: {
    backgroundColor: colors.primary,
    opacity:1
  },
  mainLabel: {
    fontSize: texts.buttonLabelWhite.fontSize,
    fontWeight: texts.buttonLabelWhite.fontWeight,
    color: texts.buttonLabelWhite.color,
  },
  mainDisableLabel: {
    fontSize: texts.buttonLabelWhite.fontSize,
    fontWeight: texts.buttonLabelWhite.fontWeight,
    color: texts.buttonLabelWhite.color,
  },
});
