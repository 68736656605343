import { User } from "./User";
//import { Backend } from "./Backend";


const user = User.create();
//const backend = Backend.create();


export const store = {
  user,
  // backend
};

window.MobxStore = store;
