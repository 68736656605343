import React, { useState, useEffect } from "react";
import { View, TextInput, Text, StyleSheet, TouchableOpacity, Alert, ScrollView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { supabase } from "../../backend/supabase";
import JsonEditor from "../inputs/JsonField";
import Tooltip from "../tooltips/Tooltip";

// DynamicForm.js

const DynamicForm = ({ settings, style }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [showTooltip, setShowTooltip] = useState(null);

  const navigation = useNavigation();
  const route = useRoute();
  const { id } = route.params || {};

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const { data: record, error } = await supabase.from(settings.data.table).select("*").eq("id", id).single();
        if (error) {
          console.error("Error fetching data:", error);
        } else {
          setFormData(record);
        }
      };
      fetchData();
    }
  }, [id]);

  const calculateFunctions = {
    getUserId: async (params = {}) => {
      // console.log("Calculating user id", params);
      const {
        data: { user },
      } = await supabase.auth.getUser();
      // console.log("Finished user id",  user?.[params.field]);
      return params.field ? user?.[params.field] : user?.id;
    },
  };

  const calculateFieldValue = async (fieldName) => {
    try {
      const fieldConfig = settings.fields.find((field) => field.name === fieldName);

      if (fieldConfig?.calculateValue?.type === "function") {
        const functionName = fieldConfig.calculateValue.name;
        const params = fieldConfig.calculateValue.params;

        if (calculateFunctions[functionName]) {
          const calculatedValue = await calculateFunctions[functionName](params);
          // console.log(`Got calculated value for field ${fieldName}:`, calculatedValue);

          await handleInputChange(fieldName, calculatedValue);

          return calculatedValue;
        }
      }
    } catch (error) {
      console.error(`Error calculating value for field ${fieldName}:`, error);
    } finally {
      // console.log(`Finished Calculated value for field ${fieldName}:`, formData);
    }
  };

  const handleInputChange = (name, value) => {
    //console.log("Handling input change", {name, value, formData});
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value, returnError = false) => {
    const fieldConfig = settings.fields.find((field) => field.name === name);
    let errorMessage = "";

    if (fieldConfig.validation) {
      const { required, minLength, maxLength, pattern, min, max } = fieldConfig.validation;

      if (required && !value) {
        errorMessage = `${fieldConfig.label} is required.`;
      } else if (minLength && value.length < minLength) {
        errorMessage = `${fieldConfig.label} must be at least ${minLength} characters.`;
      } else if (maxLength && value.length > maxLength) {
        errorMessage = `${fieldConfig.label} must be less than ${maxLength} characters.`;
      } else if (pattern && !pattern.test(value)) {
        errorMessage = `Please enter a valid ${fieldConfig.label.toLowerCase()}.`;
      } else if (fieldConfig.type === "number") {
        const numericValue = Number(value);
        if (min !== undefined && numericValue < min) {
          errorMessage = `${fieldConfig.label} cannot be less than ${min}.`;
        } else if (max !== undefined && numericValue > max) {
          errorMessage = `${fieldConfig.label} cannot be greater than ${max}.`;
        }
      }
    }

    if (returnError) {
      return errorMessage;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    }
  };

  const handleSubmit = async () => {
    // console.log("Starting form submission...", {table:settings.data.table});

    const newErrors = {};
    let hasErrors = false;

    settings.fields.forEach((field) => {
      const errorMessage = validateField(field.name, formData[field.name], true);
      if (errorMessage) {
        newErrors[field.name] = errorMessage;
        hasErrors = true;
      }
    });

    setErrors(newErrors);

    if (hasErrors) {
      alert("Form contains errors. Please fix them before submitting.");
      return;
    }

    //const { data: { user } } = await supabase.auth.getUser();

    //setFormData({ ...formData, author_id: user?.id });

    if (id) {
      const { error } = await supabase.from(settings.data.table).update(JSON.stringify(formData)).eq("id", id);
      if (error) {
        alert("Error updating record:", error.message);
      } else {
        alert("Record updated successfully.");
        navigation.goBack();
      }
    } else {
      // console.log("proceeding to insert", formData);
      const { data, error } = await supabase
        .from(settings.data.table)
        .insert(JSON.parse(JSON.stringify(formData)))
        .select("*");

      if (error) {
        alert("Error inserting record:", error.message);
      } else {
        // console.log("Record created successfully:", data);
        alert("Record created successfully.");
        if (settings.properties && settings.properties.success_submit_navigate_route) {
          navigation.navigate(settings.properties.success_submit_navigate_route);
        } else {
          navigation.goBack();
        }
      }
    }
  };

  const renderField = (field) => {
    const commonProps = {
      key: field.name,
      style: [styles.input, errors[field.name] && styles.inputError],
      onChangeText: (value) => handleInputChange(field.name, value),
      value: formData[field.name] ? String(formData[field.name]) : "",
      placeholder: `Enter ${field.label}`,
    };

    let inputElement = null;

    switch (field.type) {
      case "string":
        inputElement = <TextInput {...commonProps} />;
        break;
      case "text":
        inputElement = <TextInput {...commonProps} multiline numberOfLines={4} />;
        break;
      case "number":
        inputElement = <TextInput {...commonProps} keyboardType="numeric" />;
        break;
      case "json":
        console.log("Received JSON field", { name: field.name, value: formData[field.name] });
        inputElement = (
          <View style={{ height: 300 }}>
            <JsonEditor
              initialValue={formData[field.name] ? String(formData[field.name]) : '{"example": "data"}'}
              onChangeText={(value) => handleInputChange(field.name, value)}
              style={{ height: 300 }}
            />
          </View>
        );
        break; // Add this break statement

      case "hidden":
        useEffect(() => {
          // console.log("Calculating hidden field value for", field.name);
          calculateFieldValue(field.name, formData, setFormData);
        }, []); // Run once when component mounts
        break;
      default:
        inputElement = <TextInput {...commonProps} />;
        break;
    }

    return (
      <View key={field.name} style={styles.inputContainer}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>{field.label}</Text>
          {field.tooltip && (
            <Tooltip
              isVisible={showTooltip === field.name}
              content={field.tooltip}
              placement="top"
              onClose={() => setShowTooltip(null)}
            >
              <TouchableOpacity onPress={() => setShowTooltip(field.name)}>
                <Icon name="information-outline" size={18} color="gray" style={styles.tooltipIcon} />
              </TouchableOpacity>
            </Tooltip>
          )}
        </View>

        {inputElement}
        {errors[field.name] ? <Text style={styles.errorText}>{errors[field.name]}</Text> : null}
      </View>
    );
  };

  return (
    <View style={[styles.formContainer, style.containerStyle]}>
      {settings.fields.map((field) => renderField(field))}
      <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
        <Text style={styles.submitButtonText}>Submit</Text>
      </TouchableOpacity>
    </View>
  );
};

export default DynamicForm;

const styles = StyleSheet.create({
  formContainer: {
    width: "90%",
    padding: 20,
    borderRadius: 12,
    backgroundColor: "#F9F9F9",
    margin: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  inputContainer: {
    marginBottom: 20,
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  label: {
    fontSize: 16,
    color: "#333",
    flex: 1,
  },
  tooltipIcon: {
    marginLeft: 5,
  },
  input: {
    borderWidth: 1,
    borderColor: "#DDD",
    borderRadius: 8,
    padding: 12,
    fontSize: 16,
    backgroundColor: "#FFF",
  },
  inputError: {
    borderColor: "#FF6B6B",
  },
  errorText: {
    color: "#FF6B6B",
    marginTop: 5,
    fontSize: 14,
  },
  submitButton: {
    backgroundColor: "#559EFF",
    paddingVertical: 15,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 10,
  },
  submitButtonText: {
    color: "#FFF",
    fontSize: 18,
  },
});

// settings.js

// data.js
