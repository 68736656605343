import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet, Dimensions, Platform } from 'react-native';

export const AlertDialog = ({ 
  isVisible, 
  title, 
  message, 
  buttons = [],
  onClose,
  containerStyle,
  titleStyle,
  messageStyle,
  buttonContainerStyle,
  buttonStyle,
  buttonTextStyle,
}) => {
  const isHorizontalLayout = buttons.length <= 2;
  
  const renderButtons = () => {
    // For horizontal layout (2 or fewer buttons)
    if (isHorizontalLayout) {
      return (
        <View style={[styles.buttonRowContainer, buttonContainerStyle]}>
          {buttons.map((button, index) => (
            <TouchableOpacity
              key={index}
              style={[
                styles.button,
                isHorizontalLayout && styles.buttonHorizontal,
                index === 0 && buttons.length === 2 && styles.buttonLeft,
                index === 1 && styles.buttonRight,
                button.style === 'cancel' && styles.cancelButton,
                button.style === 'destructive' && styles.destructiveButton,
                buttonStyle,
                button.buttonStyle,
              ]}
              onPress={() => {
                onClose();
                button.onPress?.();
              }}
            >
              <Text
                style={[
                  styles.buttonText,
                  button.style === 'cancel' && styles.cancelButtonText,
                  button.style === 'destructive' && styles.destructiveButtonText,
                  buttonTextStyle,
                  button.textStyle,
                ]}
              >
                {button.text}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      );
    }
    
    // For vertical layout (3 or more buttons)
    return (
      <View style={[styles.buttonColumnContainer, buttonContainerStyle]}>
        {buttons.map((button, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.button,
              styles.buttonVertical,
              index === 0 && styles.buttonFirstVertical,
              index === buttons.length - 1 && styles.buttonLastVertical,
              button.style === 'cancel' && styles.cancelButton,
              button.style === 'destructive' && styles.destructiveButton,
              buttonStyle,
              button.buttonStyle,
            ]}
            onPress={() => {
              onClose();
              button.onPress?.();
            }}
          >
            <Text
              style={[
                styles.buttonText,
                button.style === 'cancel' && styles.cancelButtonText,
                button.style === 'destructive' && styles.destructiveButtonText,
                buttonTextStyle,
                button.textStyle,
              ]}
            >
              {button.text}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  return (
    <Modal
      transparent
      visible={isVisible}
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <View style={[styles.container, containerStyle]}>
          {title && (
            <Text style={[styles.title, titleStyle]}>
              {title}
            </Text>
          )}
          {message && (
            <Text style={[styles.message, messageStyle]}>
              {message}
            </Text>
          )}
          {renderButtons()}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor:  '#FFF',
    borderRadius: 14,
    maxWidth:  270,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    textAlign: 'center',
    color: '#000',
    paddingTop: 20,
    paddingHorizontal: 16,
  },
  message: {
    fontSize: 13,
    textAlign: 'center',
    color: '#666',
    paddingTop: 12,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  buttonRowContainer: {
    flexDirection: 'row',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: '#ccc',
  },
  buttonColumnContainer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: '#ccc',
  },
  button: {
    paddingVertical: 12,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonHorizontal: {
    flex: 1,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: '#ccc',
  },
  buttonVertical: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#ccc',
  },
  buttonLeft: {
    borderBottomLeftRadius: 14,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: '#ccc',
  },
  buttonRight: {
    borderBottomRightRadius: 14,
    borderRightWidth: 0,
  },
  buttonFirstVertical: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: '#ccc',
  },
  buttonLastVertical: {
    borderBottomWidth: 0,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  buttonText: {
    fontSize: 17,
    color: '#007AFF',
    fontWeight: '500',
    textAlign: 'center',
  },
  cancelButton: {
    backgroundColor: 'transparent',
  },
  cancelButtonText: {
    fontWeight: '600',
  },
  destructiveButton: {
    backgroundColor: 'transparent',
  },
  destructiveButtonText: {
    color: '#FF3B30',
  },
});

// Example usage:
export const useAlertDialog = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [config, setConfig] = React.useState({
    title: '',
    message: '',
    buttons: [],
  });

  const show = (title, message, buttons) => {
    setConfig({ title, message, buttons });
    setIsVisible(true);
  };

  const hide = () => {
    setIsVisible(false);
  };

  const AlertDialogComponent = () => (
    <AlertDialog
      isVisible={isVisible}
      title={config.title}
      message={config.message}
      buttons={config.buttons}
      onClose={hide}
    />
  );

  return {
    show,
    hide,
    AlertDialogComponent,
  };
};